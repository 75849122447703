import {
  Grid,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const PanelCarousel = styled(Grid)(({ theme }) => ({
  height: 250,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',

  [theme.breakpoints.up('sm')]: {
    height: 300,
  },
  [theme.breakpoints.up('md')]: {
    height: 400,
  },
  [theme.breakpoints.up('lg')]: {
    height: 500,
  },
  [theme.breakpoints.up('xl')]: {
    height: 600,
  },
}));

export const ContainerTextCarousel = styled('div')(({ theme }) => ({
  position: 'absolute',
  bottom: 30,
  textAlign: 'left',
  margin: '0px 10px'
}));

export const TitleCarousel = styled('p')(({ theme }) => ({
  color: '#ffffff',
  fontWeight: 600,
  fontSize: 40,
  marginBottom: 15
}));

export const SubTitleCarousel = styled('p')(({ theme }) => ({
  color: '#ffffff',
  maxWidth: 800,
  fontStyle: 'italic',
  fontSize: 18,
}));

export const BlackPanel = styled(Grid)(({ theme }) => ({
  width: '100%',
  height: '100%',
  backgroundColor: '#000000',
  opacity: 0.5,
  position: 'absolute',
  top: 0,
  zIndex: 2,

  '&:hover': {
    opacity: 0,
  },
}));

export const FirstFooter = styled(Grid)(({ theme }) => ({
  textAlign: 'center',

  [theme.breakpoints.up('md')]: {
    textAlign: 'left',
    paddingLeft: 10,
  },
}));

export const SecondFooter = styled(Grid)(({ theme }) => ({
  display: 'none',
  padding: '0px 20px',

  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));
