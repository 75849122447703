import React from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { Grid } from "@mui/material";
import { FirstFooter, SecondFooter } from "./styleComponent";

// import FacebookIcon from "@mui/icons-material/Facebook";
// import InstagramIcon from "@mui/icons-material/Instagram";
import LinkdedinIcon from "@mui/icons-material/LinkedIn";

function Index({
  dataCategoryProduct,
  dataCategoryProject,
  dataService,
  dataAboutUs,
}) {
  const history = useHistory();
  return (
    <>
      {/* MEDSOS */}
      <Grid
        style={{
          backgroundColor: "#2929A5",
          display: "flex",
          justifyContent: "center",
          color: "#FFFFFF",
        }}
      >
        <Grid
          container
          style={{
            maxWidth: 950,
            padding: "10px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <b style={{ marginRight: 20 }}>Follow Us</b>
          <LinkdedinIcon
            style={{ marginRight: 20, cursor: "pointer" }}
            onClick={() =>
              window.open("https://www.linkedin.com/company/rematha/")
            }
          />
          {/* <InstagramIcon
            style={{ cursor: "pointer" }}
            onClick={() =>
              window.open("https://www.instagram.com/betonperkasawijaksana/")
            }
          /> */}
        </Grid>
      </Grid>

      <Grid
        style={{
          backgroundColor: "#aeaeae",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Grid
          container
          style={{
            maxWidth: 950,
            padding: "20px 0px",
            display: "flex",
          }}
        >
          <FirstFooter item xs={12} md={4} style={{ lineHeight: 1.3 }}>
            <h3 style={{ margin: 0, fontSize: 18 }}>PT Rematha Daksa Optima</h3>
            <h4 style={{ margin: 0, marginBottom: 20, fontSize: 18 }}>
              Design & Engineering Consultant
            </h4>
            <b style={{ fontSize: 18 }}>Head Office</b>
            <p style={{ fontSize: 16 }}>
              Jl. Panjang Surya Gardenia Blok W No. 4
            </p>
            <p style={{ fontSize: 16 }}>
              Kebon Jeruk, Jakarta 11520 - Indonesia
            </p>
            <p style={{ fontSize: 16 }}>Phone : +62(21) 581 7511</p>
            {/* <p style={{ fontSize: 16 }}>Fax : +62(21) 581 7514 </p> */}
            <p style={{ fontSize: 16 }}>Mail : info@rematha.co.id</p>
            <p style={{ fontSize: 16 }}>Website : www.rematha.co.id</p>
          </FirstFooter>

          <SecondFooter item md={8}>
            <Grid style={{ width: "33%" }}>
              <b style={{ fontSize: 18 }}>Our Project</b>
              {dataCategoryProject.map((categoryProject, index) => (
                <p
                  style={{ fontSize: 16, cursor: "pointer", lineHeight: 1.4 }}
                  onClick={() =>
                    history.push("/project", { id: categoryProject.id })
                  }
                  key={`categoryProject-${index}`}
                >
                  {categoryProject.name}
                </p>
              ))}
              {/* {dataCategoryProduct.map((categoryProduct, index) => (
                <p
                  style={{ fontSize: 14, cursor: "pointer" }}
                  onClick={() =>
                    history.push("/product", { id: categoryProduct.id })
                  }
                  key={`categoryProduct-${index}`}
                >
                  {categoryProduct.name}
                </p>
              ))} */}
            </Grid>

            {/* <Grid style={{ width: "33%" }}>
              <b style={{  fontSize: 15 }}>
                Our Services
              </b>
              {dataService.map((service, index) => (
                <p
                  style={{ fontSize: 14, cursor: "pointer" }}
                  onClick={() => history.push("/service", { id: service.id })}
                  key={`service-${index}`}
                >
                  {service.title}
                </p>
              ))}
            </Grid> */}
            {/* <p style={{ fontSize: 14 }}>Overview</p> */}

            <Grid style={{ width: "33%" }}>
              <b style={{ fontSize: 18 }}>Our Company</b>
              {dataAboutUs.map((aboutus, index) => (
                <p
                  style={{ fontSize: 16, cursor: "pointer", lineHeight: 1.4 }}
                  onClick={() => history.push("/about-us", { id: aboutus.id })}
                  key={`about-us-${index}`}
                >
                  {aboutus.title}
                </p>
              ))}
              <p
                style={{ fontSize: 16, cursor: "pointer" }}
                onClick={() => history.push("/sitemap")}
              >
                Sitemap
              </p>
            </Grid>
          </SecondFooter>
        </Grid>
      </Grid>

      <Grid
        style={{
          backgroundColor: "#303030",
          padding: "30px auto",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: 50,
        }}
      >
        <p style={{ color: "#ffffff", fontSize: 12 }}>
          © Copyright 2022 PT Rematha Daksa Optima, All Right Reserved
        </p>
      </Grid>
    </>
  );
}

const mapStateToProps = ({
  dataCategoryProduct,
  dataCategoryProject,
  dataService,
  dataAboutUs,
}) => {
  return {
    dataCategoryProduct,
    dataCategoryProject,
    dataService,
    dataAboutUs,
  };
};

export default connect(mapStateToProps)(Index);
