import React, { Component } from "react";
import Helmet from "react-helmet";

export default class helmet extends Component {
  render() {
    return (
      <Helmet>
        <title>{`${
          this.props.title && `${this.props.title} | `
        } PT Rematha Daksa Optima`}</title>
        <meta name="description" content={this.props.description} />
        <meta name="keywords" content={this.props.keywords} />
      </Helmet>
    );
  }
}
