import * as React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  AppBar,
  Box,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  Grid,
  Link,
  Collapse,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

import { NavMenu, BurgerMenu, Nav, ContainerGrid } from "./styleComponent";

import Logo from "../../assets/RDO logo jpg.jpg";
// import contactUsImg from '../../assets/sub-menu/contact-us.jpg';
// import ourLocationImg from '../../assets/sub-menu/our-locations.jpg';
// import serviceImg from '../../assets/sub-menu/services.jpg';

import {
  fetchDataCategoryProduct,
  fetchDataCategoryProject,
  // fetchDataService,
  fetchDataAboutUs,
  // fetchDataNews,
  // fetchDataEvent,
  fetchBanner,
  fetchMetaTag,
} from "../../store/action";
// import { BaseURL } from '../../config/API';

function Navbar({
  fetchDataCategoryProduct,
  // fetchDataService,
  dataCategoryProduct,
  fetchDataCategoryProject,
  fetchDataAboutUs,
  // fetchDataNews,
  // fetchDataEvent,
  dataCategoryProject,
  dataAboutUs,
  fetchBanner,
  fetchMetaTag,
}) {
  const history = useHistory();
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [menuSelected, setMenuSelected] = React.useState(null);
  const [menuDrawerSelected, setMenuDrawerSelected] = React.useState(null);

  React.useEffect(() => {
    async function fetch() {
      await fetchMetaTag();
      fetchBanner();
      fetchDataAboutUs();
      fetchDataCategoryProduct();
      // fetchDataService();
      fetchDataCategoryProject();
      // fetchDataNews();
      // fetchDataEvent();
    }
    fetch();
    // eslint-disable-next-line
  }, []);

  const handleMenuSelected = (event, args) => {
    if (args === menuSelected) {
      setMenuSelected(null);
    } else {
      setMenuSelected(args);
    }
  };

  const handleMenuDrawerSelected = (event, args) => {
    if (args === menuDrawerSelected) {
      setMenuDrawerSelected(null);
    } else {
      setMenuDrawerSelected(args);
    }
  };

  const navigate = (args, data) => () => {
    setMenuSelected(null);
    setMenuDrawerSelected(null);
    setOpenDrawer(false);

    if (data) history.push(args, { id: data });
    else history.push(args);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="fixed"
        style={{
          maxWidth: "1920px",
          left: 0,
          margin: "0px auto",
        }}
      >
        <Toolbar
          style={{
            height: 64,
            width: "100%",
            maxWidth: "1920px",
            backgroundColor: "#ffffff",
            padding: 0,
          }}
        >
          <ContainerGrid>
            <img
              src={Logo}
              style={{ width: "260px", cursor: "pointer" }}
              alt="logo-BPW"
              onClick={navigate("/")}
            />

            <NavMenu>
              {/* <Nav
                onClick={(event) => handleMenuSelected(event, "product")}
                style={{
                  borderBottom: menuSelected === "product" && "5px solid red",
                  padding: menuSelected === "product" && "10px 15px 6px",
                }}
              >
                Product & Services
              </Nav> */}
              {/* <Nav
                onClick={(event) => handleMenuSelected(event, "project")}
                style={{
                  borderBottom: menuSelected === "project" && "5px solid red",
                  padding: menuSelected === "project" && "10px 15px 6px",
                }}
              >
                Project
              </Nav> */}
              <Nav
                onClick={(event) => handleMenuSelected(event, "about")}
                style={{
                  borderBottom: menuSelected === "about" && "5px solid #FFFF29",
                  padding: menuSelected === "about" && "10px 15px 6px",
                }}
              >
                About Us
              </Nav>
              <Nav
                onClick={(event) => handleMenuSelected(event, "project")}
                style={{
                  borderBottom:
                    menuSelected === "project" && "5px solid #FFFF29",
                  padding: menuSelected === "project" && "10px 15px 6px",
                }}
              >
                Project
              </Nav>
              <Nav
                onClick={(event) => handleMenuSelected(event, "contact")}
                style={{
                  borderBottom:
                    menuSelected === "contact" && "5px solid #FFFF29",
                  padding: menuSelected === "contact" && "10px 15px 6px",
                }}
              >
                Contact
              </Nav>
            </NavMenu>

            <BurgerMenu>
              <MenuIcon onClick={() => setOpenDrawer(!openDrawer)} />
            </BurgerMenu>
          </ContainerGrid>
        </Toolbar>

        {menuSelected && (
          <Box
            style={{
              backgroundColor: "#ebeced",
              padding: "20px 10px",
              display: "flex",
              overflowX: "auto",
              alignItems: "center",
            }}
            onClick={() => setMenuSelected(null)}
          >
            {menuSelected === "product" ? (
              <>
                {dataCategoryProduct.map((category, index) => (
                  <Grid
                    style={{
                      width: 177,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      marginLeft: "1%",
                      marginRight: "2%",
                      cursor: "pointer",
                    }}
                    onClick={navigate("/product", category.id)}
                    key={`category-product-${index}`}
                  >
                    {/* <img src={`${BaseURL}/category-product/${category.thumbnail}`} alt={`category-${index}`} style={{ width: 177, height: 110, marginBottom: 5 }} /> */}
                    <b style={{ color: "#606060" }}>{category.name}</b>
                  </Grid>
                ))}
                <Grid
                  style={{
                    width: 177,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginLeft: "1%",
                    marginRight: "2%",
                    cursor: "pointer",
                  }}
                  onClick={navigate("/service")}
                >
                  {/* <img src={serviceImg} alt="service" style={{ width: 177, height: 110, marginBottom: 5 }} /> */}
                  <b style={{ color: "#606060" }}>Services</b>
                </Grid>
              </>
            ) : menuSelected === "project" ? (
              <>
                {dataCategoryProject.map((category, index) => (
                  <Grid
                    style={{
                      width: 177,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      marginLeft: "1%",
                      marginRight: "2%",
                      cursor: "pointer",
                    }}
                    onClick={navigate("/project", category.id)}
                    key={`project-${index}`}
                  >
                    {/* <img src={`${BaseURL}/category-product/${category.thumbnail}`} alt={`category-${index}`} style={{ width: 177, height: 110, marginBottom: 5 }} /> */}
                    <b style={{ color: "#606060" }}>{category.name}</b>
                  </Grid>
                ))}
              </>
            ) : menuSelected === "about" ? (
              <>
                {dataAboutUs.map((el, index) => (
                  <Grid
                    style={{
                      width: 177,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      marginLeft: "1%",
                      marginRight: "2%",
                      cursor: "pointer",
                    }}
                    onClick={navigate("/about-us", el.id)}
                    key={`about-${index}`}
                  >
                    {/* <img src={`${BaseURL}/el-product/${el.thumbnail}`} alt={`el-${index}`} style={{ width: 177, height: 110, marginBottom: 5 }} /> */}
                    <b style={{ color: "#606060", textAlign: "center" }}>
                      {el.title}
                    </b>
                  </Grid>
                ))}
                {/* <Grid
                  style={{
                    width: 177,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginLeft: "1%",
                    marginRight: "2%",
                    cursor: "pointer",
                  }}
                  onClick={navigate("/about-us", "news")}
                >
                  <b style={{ color: "#606060", textAlign: "center" }}>
                    News & Event
                  </b>
                </Grid> */}
                {/* <img src={`${BaseURL}/el-product/${el.thumbnail}`} alt={`el-${index}`} style={{ width: 177, height: 110, marginBottom: 5 }} /> */}
              </>
            ) : (
              menuSelected === "contact" && (
                <>
                  <Grid
                    style={{
                      width: 177,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      marginLeft: "1%",
                      marginRight: "2%",
                      cursor: "pointer",
                    }}
                    onClick={navigate("/contact-us", { status: "contact" })}
                  >
                    {/* <img src={contactUsImg} alt="contact-us" style={{ width: 177, height: 110, marginBottom: 5 }} /> */}
                    <b style={{ color: "#606060" }}>Contact Us</b>
                  </Grid>

                  {/* <Grid
                    style={{
                      width: 177,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      marginLeft: "1%",
                      marginRight: "2%",
                      cursor: "pointer",
                    }}
                    onClick={navigate("/our-location", { status: "location" })}
                  >
                    <b style={{ color: "#606060" }}>Our Locations</b>
                  </Grid> */}
                  {/* <img src={ourLocationImg} alt="our-location" style={{ width: 177, height: 110, marginBottom: 5 }} /> */}
                </>
              )
            )}
          </Box>
        )}

        <Drawer
          anchor="right"
          open={openDrawer}
          onClose={() => setOpenDrawer(false)}
        >
          <Box
            sx={{ width: 250 }}
            role="presentation"
            // onClick={() => setOpenDrawer(false)}
            // onKeyDown={() => setOpenDrawer(false)}
          >
            <List>
              <ListItem
                button
                key="Product & Services"
                onClick={(event) => handleMenuDrawerSelected(event, "product")}
              >
                <ListItemText primary="Product & Services" />
              </ListItem>
              <Collapse
                in={menuDrawerSelected === "product"}
                timeout="auto"
                unmountOnExit
                style={{ backgroundColor: "#efefef" }}
              >
                <List component="div" disablePadding>
                  {dataCategoryProduct.map((category, index) => (
                    <ListItem
                      button
                      key={"category" + index}
                      component={Link}
                      onClick={navigate("/product", category.id)}
                    >
                      <ListItemText
                        primary={category.name}
                        style={{ color: "#626262" }}
                      />
                    </ListItem>
                  ))}
                  <ListItem
                    button
                    key="Services"
                    component={Link}
                    onClick={navigate("/service")}
                    // to={submenu.link}
                  >
                    <ListItemText
                      primary="Services"
                      style={{ color: "#626262" }}
                    />
                  </ListItem>
                </List>
              </Collapse>
              <ListItem
                button
                key="Project"
                onClick={(event) => handleMenuDrawerSelected(event, "project")}
              >
                <ListItemText primary="Project" />
              </ListItem>
              <Collapse
                in={menuDrawerSelected === "project"}
                timeout="auto"
                unmountOnExit
                style={{ backgroundColor: "#efefef" }}
              >
                <List component="div" disablePadding>
                  {dataCategoryProject.map((category, index) => (
                    <ListItem
                      button
                      key={"category" + index}
                      component={Link}
                      onClick={navigate("/project", category.id)}
                    >
                      <ListItemText
                        primary={category.name}
                        style={{ color: "#626262" }}
                      />
                    </ListItem>
                  ))}
                </List>
              </Collapse>
              <ListItem
                button
                key="About Us"
                onClick={(event) => handleMenuDrawerSelected(event, "about")}
              >
                <ListItemText primary="About Us" />
              </ListItem>
              <Collapse
                in={menuDrawerSelected === "about"}
                timeout="auto"
                unmountOnExit
                style={{ backgroundColor: "#efefef" }}
              >
                <List component="div" disablePadding>
                  {dataAboutUs.map((about, index) => (
                    <ListItem
                      button
                      key={"about" + index}
                      component={Link}
                      onClick={navigate("/about-us", about.id)}
                    >
                      <ListItemText
                        primary={about.title}
                        style={{ color: "#626262" }}
                      />
                    </ListItem>
                  ))}
                  <ListItem
                    button
                    component={Link}
                    onClick={navigate("/about-us", "news")}
                  >
                    <ListItemText
                      primary="News & Event"
                      style={{ color: "#626262" }}
                    />
                  </ListItem>
                </List>
              </Collapse>
              <ListItem
                button
                key="Contact"
                onClick={(event) => handleMenuDrawerSelected(event, "contact")}
              >
                <ListItemText primary="Contact" />
              </ListItem>
              <Collapse
                in={menuDrawerSelected === "contact"}
                timeout="auto"
                unmountOnExit
                style={{ backgroundColor: "#efefef" }}
              >
                <List component="div" disablePadding>
                  <ListItem
                    button
                    key="Services"
                    onClick={navigate("/contact-us")}
                  >
                    <ListItemText
                      primary="Contact Us"
                      style={{ color: "#626262" }}
                    />
                  </ListItem>
                  <ListItem
                    button
                    key="Services"
                    onClick={navigate("/our-location")}
                  >
                    <ListItemText
                      primary="Our Locations"
                      style={{ color: "#626262" }}
                    />
                  </ListItem>
                </List>
              </Collapse>
              {/* 
              <Grid style={{ width: 177, display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: '1%', marginRight: '2%', cursor: 'pointer' }} >
                <img src={contactUsImg} alt="contact-us" style={{ width: 177, height: 110, marginBottom: 5 }} />
                <b style={{ color: '#606060' }}>Contact Us</b>
              </Grid>

              <Grid style={{ width: 177, display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: '1%', marginRight: '2%', cursor: 'pointer' }} onClick={navigate('/our-location')}>
                <img src={ourLocationImg} alt="our-location" style={{ width: 177, height: 110, marginBottom: 5 }} />
                <b style={{ color: '#606060' }}>Our Locations</b>
              </Grid> */}
            </List>
          </Box>
        </Drawer>
      </AppBar>
    </Box>
  );
}

const mapStateToProps = ({
  dataCategoryProduct,
  dataCategoryProject,
  dataAboutUs,
}) => {
  return {
    dataCategoryProduct,
    dataCategoryProject,
    dataAboutUs,
  };
};

const mapDispatchToProps = {
  fetchDataCategoryProduct,
  // fetchDataService,
  fetchDataCategoryProject,
  fetchDataAboutUs,
  // fetchDataNews,
  // fetchDataEvent,
  fetchBanner,
  fetchMetaTag,
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
